window.addEventListener("load", onPageLoad);

let menuOpen = false;

function onPageLoad() {
  const menuButton = document.querySelector("#menu-button");

  document.getElementById("year").innerHTML = new Date().getFullYear();

  menuButton.addEventListener("click", toggleMenuButton);
  document.querySelector("body").addEventListener("click", closeMenu);
}

function toggleMenuButton(event) {
  event.stopPropagation();
  const menuElement = document.querySelector("#menu-button .menu-icon");
  const closeElement = document.querySelector("#menu-button .close-icon");
  const drawer = document.querySelector("#menu-drawer");

  if (!menuOpen) {
    closeElement.classList.remove("hidden");
    menuElement.classList.add("hidden");
    drawer.classList.remove("hidden");
  }

  if (menuOpen) {
    closeElement.classList.add("hidden");
    menuElement.classList.remove("hidden");
    drawer.classList.add("hidden");
  }
  menuOpen = !menuOpen;
}

function closeMenu() {
  const menuElement = document.querySelector("#menu-button .menu-icon");
  const closeElement = document.querySelector("#menu-button .close-icon");
  const drawer = document.querySelector("#menu-drawer");

  if (menuOpen) {
    closeElement.classList.add("hidden");
    menuElement.classList.remove("hidden");
    drawer.classList.add("hidden");
  }
  menuOpen = !menuOpen;
}
